import {iot, mqtt5} from "aws-iot-device-sdk-v2";
import {IOT_AUTHORIZER_NAME} from "src/constants/MqttConstants";
import {ICrtError} from "aws-crt";
import {MqttConnectCustomAuthConfig} from "aws-crt/dist.browser/common/aws_iot_shared";
import {once} from "events";
import {Mqtt5Client} from "aws-crt/dist.browser/browser/mqtt5";
import initialMetricsPublisher from "src/metrics";

interface MqttClientProps {
    readonly endpoint: string,
    readonly clientId: string,
    readonly username: string
}

export class MqttService {
    static instance: MqttService;
    client: mqtt5.Mqtt5Client

    private static metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('MqttService');

    static getInstance() {
        if (!this.instance) {
            console.error("Call init first") // TODO: remove console and place logging
            return undefined;
        }
        return this.instance;
    }

    static init(props: MqttClientProps) {
        if (this.instance) {
            console.warn("Client already initialized"); // TODO: remove console and place logging
            return
        }
        this.instance = new MqttService(props);
    }

    private constructor(props: MqttClientProps) {
        let authorizer: MqttConnectCustomAuthConfig = {
            authorizerName: IOT_AUTHORIZER_NAME,
            username: props.username
        };
        let clientConfig = iot.AwsIotMqtt5ClientConfigBuilder
            .newWebsocketMqttBuilderWithCustomAuth(props.endpoint, authorizer)
            .withConnectProperties({
                clientId: props.clientId,
                keepAliveIntervalSeconds: 1200
            })
            .build();
        this.client = new mqtt5.Mqtt5Client(clientConfig);
    }

    onClientError = (callback: (event: ICrtError) => void) => {
        this.client.on('error', (error: ICrtError) => {
            MqttService.metricsPublisher.publishCounterMonitor('Error', 1);

            console.log("MQTT: error", error);
            callback(error)
        });
    }

    onMessageReceived = (callback: (event: mqtt5.MessageReceivedEvent) => void) => {
        this.client.on('messageReceived',(eventData: mqtt5.MessageReceivedEvent) : void => {
            MqttService.metricsPublisher.publishCounterMonitor('Received', 1);

            console.log("MQTT: Received", eventData)
            callback(eventData)
        } );
    }

    onAttemptingConnect = (callback: (event: mqtt5.AttemptingConnectEvent) => void) => {
        this.client.on('attemptingConnect',(eventData: mqtt5.AttemptingConnectEvent) : void => {
            MqttService.metricsPublisher.publishCounterMonitor('Attempting', 1);

            console.log("MQTT: attempting", eventData)
            callback(eventData)
        } );
    }

    onConnectionSuccess = (callback: (event: mqtt5.ConnectionSuccessEvent) => void) => {
        this.client.on('connectionSuccess', (eventData: mqtt5.ConnectionSuccessEvent) => {
            let code =  eventData.connack?.reasonCode
            MqttService.metricsPublisher.publishCounterMonitor('Success', 1);
            MqttService.metricsPublisher.publishCounterMonitor('Success.' + code, 1);

            console.log("MQTT: success", eventData)
            callback(eventData)
        });
    }

    onConnectionFailure = (callback: (eventData: mqtt5.ConnectionFailureEvent) => void) => {
        this.client.on('connectionFailure', (eventData: mqtt5.ConnectionFailureEvent) => {
            console.log("MQTT: failure", eventData)
            MqttService.metricsPublisher.publishCounterMonitor('Failure', 1);
            callback(eventData)
        });
    }

    onDisconnection = (callback: (eventData: mqtt5.DisconnectionEvent) => void) => {
        this.client.on('disconnection', (eventData: mqtt5.DisconnectionEvent) => {
            let code =  eventData.disconnect?.reasonCode
            MqttService.metricsPublisher.publishCounterMonitor('Disconnection', 1);
            MqttService.metricsPublisher.publishCounterMonitor('Disconnection.' + code, 1);

            console.log("MQTT: disconnect", eventData)
            callback(eventData)
        });
    }

    onStopped = (callback: (eventData: mqtt5.StoppedEvent) => void) => {
        this.client.on('stopped', (eventData: mqtt5.StoppedEvent) => {
            MqttService.metricsPublisher.publishCounterMonitor('Stopped', 1);
            console.log("MQTT: stopped", eventData)
            callback(eventData)
        });
    }

    /**
     * Publish messages to a given topic of mqtt client
     *
     * @param topicName - topic to publish message
     * @param data - string message to be published
     */
    publishMessage = (topicName: string, data: string) => {
        return this.client.publish({
            qos: mqtt5.QoS.AtMostOnce,
            topicName: topicName,
            payload: data
        });
    }

    /**
     * Subscribe to a topic
     *
     * @param topic - topic to subscribe
     */
    subscribe = (topic: string) => {
        return this.client.subscribe({
            subscriptions: [
                { qos: mqtt5.QoS.AtMostOnce, topicFilter: topic }
            ]
        }).then((res) => {
            console.log("Subscription success", topic);
        }).catch((err) => {
            console.error("Subscription failure", topic, err);
        });
    }

    /**
     * Handles closing client gracefully
     */
    closeService = async () => {
        const stopped = once(this.client, Mqtt5Client.STOPPED);
        this.client.stop();
        await stopped;
        this.client.close();
    }

    /**
     * Handles stopping client
     */
    stopService = async () => {
        this.client.stop();
    }

    /**
     * Handles starting client
     */
    startService = async () => {
        this.client.start();
    }
}
