import { configureStore } from '@reduxjs/toolkit'
import mobileKeyboardReducer from "../redux/firetvReducer";
import createSagaMiddleware from 'redux-saga';
import serviceSaga from './firetvSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        mobileKeyboard: mobileKeyboardReducer,
    },
    middleware: [sagaMiddleware]
});

sagaMiddleware.run(serviceSaga)