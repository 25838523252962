import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {Badge} from "src/components/Badge";
import {FireTVDPad} from "src/components/FireTVDPad";
import {InputGroup} from "src/components/InputGroup";
import {MessageType, MqttStatus, Navigation} from "src/constants/MqttConstants";
import {
    APPSTORE_LINK_DESC,
    ClientStatus,
    CONNECTION_ID_COOKIE_NAME,
    COOKIE_EXPIRATION_DATE,
    GET_PARAM_CLIENT_ID,
    GET_PARAM_REGION,
    GET_PARAM_TOKEN,
    GET_PARAM_TOPIC_NAME
} from "src/constants/ServiceConstants";
import initialMetricsPublisher from "src/metrics";
import {Overlay} from "src/pages/Overlay";
import {MqttService} from "src/service/MqttService";
import {
    handleConnectEvent,
    handleDisconnectEvent,
    handleFailureEvent,
    handleMqttMessages,
    handleUpdate,
    newConnectionId,
    regionToAWSRegion
} from "src/utils/ServiceUtils";
import {
    closeMqttService,
    initialize,
    mqttAttempting,
    mqttError,
    startMqttService,
    StateProps
} from "../redux/firetvReducer";
import {mqttReconnectAction} from 'src/redux/firetvSaga';
import {useCookies} from "react-cookie";

export const FireTVKeyboard = () => {
    const metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('FireTVKeyboard');

    const [getParams] = useSearchParams();
    const stateData: StateProps = useSelector((state: any) => state.mobileKeyboard);
    const dispatch = useDispatch();
    const [connectionIdCookie, setConnectionIdCookie] = useCookies([CONNECTION_ID_COOKIE_NAME])

    useEffect(() => {
        const token = getParams.get(GET_PARAM_TOKEN);
        const rawTopicName = getParams.get(GET_PARAM_TOPIC_NAME);
        const rawClientId = getParams.get(GET_PARAM_CLIENT_ID);
        const region = regionToAWSRegion(getParams.get(GET_PARAM_REGION));

        let connectionId = connectionIdCookie.QrKeyboardConnectionId;
        if (!connectionId) {
            connectionId = newConnectionId();
            setConnectionIdCookie(CONNECTION_ID_COOKIE_NAME, connectionId, {expires : COOKIE_EXPIRATION_DATE});
        }

        dispatch(initialize({token, rawTopicName, rawClientId, region, connectionId}));
    }, [dispatch]);

    useEffect(() => {
        switch (stateData.mqttStatus) {
            case MqttStatus.INITIALIZED:
                MqttService.getInstance()?.onConnectionSuccess((e) => {
                    handleConnectEvent(dispatch, e);
                });
                MqttService.getInstance()?.onAttemptingConnect((e) => {
                    dispatch(mqttAttempting(e));
                });
                MqttService.getInstance()?.onConnectionFailure((e) => {
                    handleFailureEvent(dispatch, e);
                });
                MqttService.getInstance()?.onDisconnection((e) => {
                    handleDisconnectEvent(dispatch, e);
                });
                MqttService.getInstance()?.onClientError((e) => {
                    dispatch(mqttError(e));
                });
                MqttService.getInstance()?.onMessageReceived((e) => {
                    handleMqttMessages(dispatch, e, stateData);
                });

                dispatch(startMqttService());
                break;

            case MqttStatus.STARTED:
                MqttService.getInstance()?.subscribe(stateData.subscribeTopics.connectTopic);
                MqttService.getInstance()?.subscribe(stateData.subscribeTopics.ftvClient);
                break;
            case MqttStatus.RECONNECTING:
                dispatch(mqttReconnectAction());
                break;
            case MqttStatus.STOPPED:
                dispatch(closeMqttService(null))
        }
    }, [stateData.mqttStatus]);

    useEffect(() => {
        if (stateData.connectionStatus == ClientStatus.CONNECTED
            || stateData.connectionStatus == ClientStatus.PENDING_AUTHORIZATION) {
            MqttService.getInstance()?.subscribe(stateData.subscribeTopics.disconnectTopic);
        } else if (stateData.connectionStatus == ClientStatus.RESTRICTED
                    || stateData.connectionStatus == ClientStatus.AUTHENTICATION_FAILURE
                    || stateData.connectionStatus == ClientStatus.DISCONNECTED) {
            MqttService.getInstance()?.stopService();
            dispatch(closeMqttService(null));
        }
    }, [stateData.connectionStatus])

    /**
     * Handles interaction with DPAD buttons
     */
    const handleDpadAction = (type: Navigation) => {
        handleUpdate(MessageType.NAVIGATION, type, dispatch, stateData);
    };

    /**
     * Handles submit button click interaction
     */
    const handleSubmit = () => {
        handleUpdate(MessageType.NAVIGATION, Navigation.SUBMIT, dispatch, stateData);
        metricsPublisher.publishCounterMonitor('Button.Submit', 1);
    };

    if (stateData.connectionStatus == ClientStatus.CONNECTED) {
        return (<>
            <div className="container">
                <div className="connection-status">
                    <div className="status">
                        {stateData.statusText}
                    </div>
                </div>
                {(() => {
                    if (stateData.keyboardVisible && stateData.inputVisible) {
                        return <>
                            <div className="input-container">
                                <InputGroup metricsPublisher={metricsPublisher}/>
                            </div>
                        </>;
                    } else
                        return <div className="empty-input-container"/>;
                })()}
                {(() => {
                    if (stateData.keyboardVisible) {
                        return <>
                            <div className="button-container">
                                <button className="action-button" onClick={handleSubmit}>Submit</button>
                            </div>
                        </>;
                    } else
                        return <div className="empty-submit-container"/>;
                })()}
                <div className={`navigation-container ${stateData.navActive ? '' : 'disabled'}`}>
                    <FireTVDPad onClickAction={handleDpadAction}/>
                </div>
                <div className="banner-container">
                    <p className="appstore-link">
                        {APPSTORE_LINK_DESC}
                    </p>
                </div>
                <div className="badge-container">
                    <Badge/>
                </div>

            </div>
        </>);
    } else {
        return (<Overlay/>);
    }
};

export default FireTVKeyboard;
