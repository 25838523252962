import {toUtf8} from "@aws-sdk/util-utf8-browser";
import {StateProps} from "../redux/firetvReducer";
import {mqtt5} from "aws-iot-device-sdk-v2";
import {DEFAULT_LABEL, FireTVLayoutType} from "../constants/ServiceConstants";
import {CLIENT_CONNECTED_TOPIC_PREFIX, CLIENT_DISCONNECTED_TOPIC_PREFIX, MessageType} from "../constants/MqttConstants";
import {
    getClientStatus,
    prepareFireTvClientId,
    preparePublishTopicName,
    prepareSubscribeTopicName
} from "src/utils/ServiceUtils";

/**
 * Validates the url params and updates them into the state
 * Creates the subscription and publishing topics to be used for communication
 * @param actions Payload passed as redux action
 * @param state state object from reducer
 */
export const initializeStateFromUrl = (actions: any, state:StateProps) => {
    const { token, rawTopicName, rawClientId, region, connectionId } = actions.payload;
    const urlParams = { token, rawTopicName, rawClientId, region, connectionId };

    if (Object.values(urlParams).every(Boolean)) {
        Object.assign(state.urlParams,
            {
                token,
                rawTopicName,
                rawClientId,
                region,
                connectionId,
                isValid: true
            });

        let ftvClient = prepareFireTvClientId(rawClientId, connectionId);
        state.subscribeTopics.connectTopic = CLIENT_CONNECTED_TOPIC_PREFIX + ftvClient;
        state.subscribeTopics.disconnectTopic = CLIENT_DISCONNECTED_TOPIC_PREFIX + ftvClient;
        state.subscribeTopics.ftvClient = prepareSubscribeTopicName(rawTopicName, connectionId);

        state.publishTopics.mobileClient = preparePublishTopicName(rawTopicName, connectionId);
    }
}

/**
 * Handles the message received from firetv
 * @param state state object from reducer
 * @param data mqtt receive event data
 */
export const messageReceivedHandler = (state: StateProps, data: mqtt5.MessageReceivedEvent) => {
    let payload = JSON.parse(toUtf8(new Uint8Array(data.message.payload as ArrayBuffer)));
    if (payload['type'] == MessageType.KEYBOARD_STATE) {
        state.connectionStatus = getClientStatus(payload['status']);

        let layout: FireTVLayoutType = payload['layout'];
        let status: string = payload['name'];
        let visibility: boolean = payload['visible'] ?? false;
        let inputLabel: string = payload['label'] || DEFAULT_LABEL;
        let pin: string = payload['pin'] ?? '';

        state.ftvInputType = layout;
        state.labelText = inputLabel;
        state.statusText = status;
        state.keyboardVisible = visibility;
        state.pin = pin;
        if (!visibility) {
            state.navActive = true;
        }
        switch(layout) {
            case FireTVLayoutType.NO_INPUT_KEYBOARD:
                state.inputVisible = false;
                break;
            case FireTVLayoutType.PASSWORD_KEYBOARD:
            case FireTVLayoutType.STANDARD_KEYBOARD:
                state.inputVisible = true;
        }
    }
    state.reconnectCount = 0; // Reset reconnect counters
}