import '../styles/dpad.scss';
import {Navigation} from "src/constants/MqttConstants";

interface FireTVDPadProps {
    /**
     * Button click callback
     * @param type Button type of {@link Navigation}
     */
    onClickAction: (type: Navigation) => void,
}

/**
 * SVG reference - https://tiny.amazon.com/3l4rp6s7/figmfileA5cQFire
 */
export const FireTVDPad = ({onClickAction}: FireTVDPadProps) => {
    return (
        <div className="ftv-dpad">
            <svg viewBox="0 0 346 346" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <g id="Outer Buttons">
                        <g id="Left Pad" className="nav-group" onClick={() => onClickAction(Navigation.LEFT)}>
                            <path className="button left" id="Subtract"
                                  d="M54.0865 58C26.7447 87.1522 10 126.363 10 169.484C10 213.495 27.442 253.431 55.7906 282.758L120.934 218.53C108.986 205.844 101.664 188.751 101.664 169.949C101.664 152.032 108.312 135.668 119.276 123.19L54.0865 58Z"
                                  fill="#5A5A5A"/>

                            <path className="arrow left" id="Fill" fillRule="evenodd" clipRule="evenodd"
                                  d="M64 181C63.488 181 62.976 180.804 62.586 180.414L52.586 170.414C51.804 169.632 51.804 168.368 52.586 167.586L62.586 157.586C63.368 156.804 64.632 156.804 65.414 157.586C66.196 158.368 66.196 159.632 65.414 160.414L56.828 169L65.414 177.586C66.196 178.368 66.196 179.632 65.414 180.414C65.024 180.804 64.512 181 64 181Z"
                                  fill="#FD7941"/>

                        </g>
                        <g id="Top Pad" className="nav-group" onClick={() => onClickAction(Navigation.UP)}>
                            <path className="button top" id="Subtract_2"
                                  d="M287.92 53.4044L221.841 118.555C209.085 106.198 191.698 98.5926 172.535 98.5926C154.189 98.5926 137.471 105.564 124.886 117.001L59.7002 51.8156C89.0298 23.4523 128.977 6 173 6C217.839 6 258.45 24.1054 287.92 53.4044Z"
                                  fill="#5A5A5A"/>
                            <g id="&#226;&#141;&#136; Bison - Icon_2">
                                <g id="caret up">
                                    <g id="Icon Tint Color_2">
                                        <path className="arrow top" id="Fill_2" fillRule="evenodd" clipRule="evenodd"
                                              d="M163 63C163.512 63 164.024 62.804 164.414 62.414L173 53.828L181.586 62.414C182.368 63.196 183.632 63.196 184.414 62.414C185.196 61.632 185.196 60.368 184.414 59.586L174.414 49.586C173.632 48.804 172.368 48.804 171.586 49.586L161.586 59.586C160.804 60.368 160.804 61.632 161.586 62.414C161.976 62.804 162.488 63 163 63Z"
                                              fill="#FD7941"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Right Pad" className="nav-group" onClick={() => onClickAction(Navigation.RIGHT)}>
                            <path className="button right" id="Subtract_3"
                                  d="M290.184 282.3L224.999 217.114C236.436 204.529 243.407 187.811 243.407 169.464C243.407 152.366 237.352 136.681 227.269 124.438L293.455 59.1821C319.883 88.1539 336 126.695 336 169C336 213.023 318.548 252.97 290.184 282.3Z"
                                  fill="#5A5A5A"/>
                            <g id="&#226;&#141;&#136; Bison - Icon_3">
                                <g id="caret right">
                                    <g id="Icon Tint Color_3">
                                        <path className="arrow right" id="Fill_3" fillRule="evenodd" clipRule="evenodd"
                                              d="M281 181C280.488 181 279.976 180.804 279.586 180.414C278.804 179.632 278.804 178.368 279.586 177.586L288.172 169L279.586 160.414C278.804 159.632 278.804 158.368 279.586 157.586C280.368 156.804 281.632 156.804 282.414 157.586L292.414 167.586C293.196 168.368 293.196 169.632 292.414 170.414L282.414 180.414C282.024 180.804 281.512 181 281 181Z"
                                              fill="#FD7941"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Bottom Pad" className="nav-group" onClick={() => onClickAction(Navigation.DOWN)}>
                            <path className="button bottom" id="Subtract_4"
                                  d="M284.484 287.913L219.295 222.724C206.816 233.688 190.452 240.336 172.536 240.336C155.077 240.336 139.092 234.023 126.74 223.555L61.49 287.889C90.6449 315.245 129.866 332 173 332C216.122 332 255.332 315.255 284.484 287.913Z"
                                  fill="#5A5A5A"/>
                            <g id="&#226;&#141;&#136; Bison - Icon_4">
                                <g id="caret down">
                                    <g id="Icon Tint Color_4">
                                        <path className="arrow bottom" id="Fill_4" fillRule="evenodd" clipRule="evenodd"
                                              d="M173 290C173.512 290 174.024 289.804 174.414 289.414L184.414 279.414C185.196 278.632 185.196 277.368 184.414 276.586C183.632 275.804 182.368 275.804 181.586 276.586L173 285.172L164.414 276.586C163.632 275.804 162.368 275.804 161.586 276.586C160.804 277.368 160.804 278.632 161.586 279.414L171.586 289.414C171.976 289.804 172.488 290 173 290Z"
                                              fill="#FD7941"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Center Button" className="center-group" onClick={() => onClickAction(Navigation.CENTER)}>
                        <circle id="Ellipse 1" className="base" cx="172.45" cy="169.379" r="64" fill="#202020"/>
                        <circle id="Ellipse 2" className="gradient" cx="172.45" cy="169.379" r="64"
                                fill="url(#paint0_linear_245_36702)"/>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_d_245_36702" x="0" y="0" width="346" height="346" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_245_36702"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_245_36702" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_245_36702" x1="172.45" y1="105.379" x2="172.45" y2="233.379"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5A5A5A"/>
                        <stop offset="1" stopColor="#5A5A5A" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint0_linear_312_204" x1="172.45" y1="105.379" x2="172.45" y2="233.379"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#8F8F8F" stopOpacity="0.533333"/>
                        <stop offset="1" stopColor="#8F8F8F" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}