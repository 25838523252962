import {createSlice} from '@reduxjs/toolkit';
import {initializeStateFromUrl, messageReceivedHandler} from 'src/utils/ReducerUtils';
import {DisconnectReason, MqttStatus} from "../constants/MqttConstants";
import {AWSRegion, ClientStatus, DEFAULT_LABEL, FireTVLayoutType} from "../constants/ServiceConstants";

export interface StateProps {
    connectionStatus: ClientStatus,
    pin: string,
    inputText: string,
    labelText: string,
    ftvInputType: FireTVLayoutType,
    inputVisible: boolean,
    keyboardVisible: boolean,
    navActive: boolean,
    firstPublish: boolean,
    firstConnectionAttempt: boolean,
    publishTopics: {
        mobileClient: string
    },
    subscribeTopics: {
        connectTopic: string,
        disconnectTopic: string,
        ftvClient: string
    },
    mqttStatus: MqttStatus,
    reconnectCount: number,
    region: AWSRegion,
    statusText: string,
    urlParams: {
        token: string,
        rawTopicName: string,
        rawClientId: string,
        region: string,
        connectionId: string,
        isValid: boolean
    }
}

const initialState = {
    connectionStatus: ClientStatus.INITIALIZING,
    pin: "",
    inputText: "",
    labelText: DEFAULT_LABEL,
    ftvInputType: FireTVLayoutType.STANDARD_KEYBOARD,
    inputVisible: true,
    keyboardVisible: true,
    navActive: true,
    firstPublish: true,
    firstConnectionAttempt: true,
    publishTopics: {
        mobileClient: ''
    },
    subscribeTopics: {
        connectTopic: '',
        disconnectTopic: '',
        ftvClient: ''
    },
    mqttStatus: MqttStatus.NOT_INITIALIZED,
    reconnectCount: 0,
    statusText: "",
    urlParams: {
        token: "",
        rawTopicName: "",
        rawClientId: "",
        region: "",
        connectionId: "",
        isValid: false
    }
} as StateProps;

/**
 * Define all action and reducers to update the state
 */
const mobileKeyboardSlice = createSlice({
    name: 'mobileKeyboard',
    initialState,
    reducers: {
        initialize(state, actions) {
            initializeStateFromUrl(actions, state);
        },
        mqttInitialized(state) {
            state.mqttStatus = MqttStatus.INITIALIZED
        },
        startMqttService() {
        },
        mqttStarted(state) {
            state.mqttStatus = MqttStatus.STARTED
        },
        mqttError(state, action) {
            state.mqttStatus = MqttStatus.STOPPED
            state.connectionStatus = ClientStatus.DISCONNECTED
        },
        mqttReconnecting(state) {
            state.mqttStatus = MqttStatus.RECONNECTING
            state.connectionStatus = ClientStatus.CONNECTING
        },
        mqttAttempting(state, action) {
            state.mqttStatus = MqttStatus.ATTEMPTING
        },
        mqttConnectionSuccess(state, action) {
            state.mqttStatus = MqttStatus.CONNECTION_SUCCESS
            state.connectionStatus = ClientStatus.CONNECTING
        },
        mqttDisconnection(state, action) {
            switch (action.payload) {
                case DisconnectReason.SESSION_TAKEN_OVER:
                    state.connectionStatus = ClientStatus.DISCONNECTED;
                    state.mqttStatus = MqttStatus.STOPPED
                    break;
                case DisconnectReason.AUTH_FAILURE:
                case DisconnectReason.OTHERS:
                    state.connectionStatus = ClientStatus.RECONNECTING;
                    state.mqttStatus = MqttStatus.RECONNECTING;
                    break;
            }
        },
        incrementReconnectCount(state) {
            state.reconnectCount += 1
        },
        setFirstConnectionAttempt(state, action) {
            state.firstConnectionAttempt = action.payload as boolean
        },
        mqttMessageReceived(state, action) {
            messageReceivedHandler(state, action.payload)
        },
        closeMqttService(state, action) {
            // Update status if provided by caller
            if (action.payload)
                state.connectionStatus = action.payload as ClientStatus
            state.mqttStatus = MqttStatus.CLOSED
        },
        badUrlParams(state) {
            state.connectionStatus = ClientStatus.DISCONNECTED
        },
        failed(state) {
            state.connectionStatus = ClientStatus.DISCONNECTED
        },
        mqttPublishMessage(state, action) {
            state.firstPublish = false;
        },
        setInputActive(state, action) {
            state.navActive = !action.payload;
        }
    },
})

export const {
    initialize,
    mqttInitialized,
    startMqttService,
    closeMqttService,
    mqttStarted,
    mqttError,
    mqttAttempting,
    mqttReconnecting,
    mqttConnectionSuccess,
    setFirstConnectionAttempt,
    mqttDisconnection,
    incrementReconnectCount,
    mqttMessageReceived,
    mqttPublishMessage,
    setInputActive,
    badUrlParams,
    failed } = mobileKeyboardSlice.actions
export default mobileKeyboardSlice.reducer
