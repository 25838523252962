import {delay, put, select, takeLatest, takeLeading} from 'redux-saga/effects'
import {
    badUrlParams,
    closeMqttService,
    failed,
    incrementReconnectCount,
    initialize,
    mqttAttempting,
    mqttConnectionSuccess,
    mqttDisconnection,
    mqttError,
    mqttInitialized,
    mqttPublishMessage,
    mqttStarted,
    setFirstConnectionAttempt,
    startMqttService
} from './firetvReducer'
import {
    getConnectionStatus,
    getIsFirstConnectionAttempt,
    getMqttStatus,
    getPublishTopic,
    getReconnectCount,
    getUrlParams
} from './selectors'
import {MqttService} from 'src/service/MqttService';
import {prepareAuthorizerUsername, prepareIOTEndpoint, prepareMobileClientId} from 'src/utils/ServiceUtils';
import {DisconnectReason, MqttStatus} from 'src/constants/MqttConstants';
import {
    ClientStatus,
    MAX_RECONNECT,
    MAX_WAIT_FOR_CLIENT_IN_MS,
    RECONNECT_RETRY_DELAY_IN_MS
} from 'src/constants/ServiceConstants';
import {createAction} from '@reduxjs/toolkit';

// Define saga workers

/**
 * Initializes the mqtt client object
 */
function* initializeMqtt(): any {
    try {
        const urlParams: any = yield select(getUrlParams);
        if (urlParams.isValid) {
            MqttService.init({
                endpoint: prepareIOTEndpoint(urlParams.region),
                clientId: prepareMobileClientId(urlParams.rawClientId, urlParams.connectionId),
                username: prepareAuthorizerUsername(urlParams.rawTopicName, urlParams.token)
            });
            yield put(mqttInitialized())
        } else {
            yield put(badUrlParams())
        }
    } catch (e) {
        yield put(badUrlParams());
    }
}

/**
 * Invokes mqtt start to establish connection with mqtt broker
 */
function* startMqtt(): any {
    try {
        yield MqttService.getInstance()?.startService();
        yield put(mqttStarted())
    } catch (e) {
        yield put(failed())
    }
}

/**
 * Publishes message to the publish topic
 * @param action 
 */
function* publishMessage(action: any): any {
    try {
        const publishTopic = yield select(getPublishTopic);

        MqttService.getInstance()?.publishMessage(publishTopic.mobileClient, action.payload).catch((e) => {
            console.error("Publish Failure", e)
            throw e;
        });
    } catch (e) {
        yield put(mqttDisconnection(DisconnectReason.OTHERS))
    }
}

/**
 * Handles reconnection if mqtt is not closed, with a max reconnection attempt
 */
function* handleReconnect() {
    const reconnectCount: number = yield select(getReconnectCount);
    const mqttStatus: MqttStatus = yield select(getMqttStatus)

    if (mqttStatus != MqttStatus.CLOSED) {
        if (reconnectCount >= MAX_RECONNECT) {
            yield put(closeMqttService(ClientStatus.AUTHENTICATION_FAILURE));
        } else {
            yield put(incrementReconnectCount())

            yield MqttService.getInstance()?.stopService();
            yield put(mqttAttempting(null));
            yield delay(RECONNECT_RETRY_DELAY_IN_MS)
            yield put(startMqttService())
        }
    }
}

/**
 * Invokes mqtt close
 */
function* handleMqttClose() {
    yield MqttService.getInstance()?.closeService();
}

/**
 * Triggers reconnection
 */
function* clientDisconnectedWorker() {
    const status: ClientStatus = yield select(getConnectionStatus);
    if (status == ClientStatus.CONNECTED || status == ClientStatus.PENDING_AUTHORIZATION) {
        yield put(mqttDisconnection(DisconnectReason.OTHERS));
    }
}

/**
 * Updates successful connectivity to mqtt and initiates action to check client connectivity
 * @param event 
 */
function* mqttConnectedWorker(event: any) {
    yield put(mqttConnectionSuccess(event));
    yield put(checkClientConnectedAction());
}

/**
 * Checks if client status is still connecting after max wait time for connection
 */
function* checkClientConnectionWorker() {
    yield delay(MAX_WAIT_FOR_CLIENT_IN_MS);
    const status: ClientStatus = yield select(getConnectionStatus);
    if (status == ClientStatus.CONNECTING) {
        yield put(mqttDisconnection(DisconnectReason.OTHERS));
    }
}

/**
 * Attempts to reconnect on first mqtt connection failure or else close connection
 */
function* mqttFailureWorker() {
    const isFirstConnectionAttempt: boolean = yield select(getIsFirstConnectionAttempt);
    if(isFirstConnectionAttempt) {
        yield MqttService.getInstance()?.stopService();
        yield put(mqttAttempting(null)); // Reset mqtt status to re-trigger subscription
        yield put(startMqttService());
        yield put(setFirstConnectionAttempt(false));
    } else {
        yield put(closeMqttService(ClientStatus.AUTHENTICATION_FAILURE));
    }
}

/**
 * Configure the action and the corresponding saga worker
 * e.g. Starts "initializeMqtt" on each dispatched "initialize" action, and automatically cancels any previous saga task if it's still running
 */
function* serviceSaga() {
    yield takeLatest(initialize, initializeMqtt);
    yield takeLatest(startMqttService, startMqtt);
    yield takeLatest(mqttPublishMessage, publishMessage);
    yield takeLeading(mqttReconnectAction, handleReconnect);
    yield takeLatest(closeMqttService, handleMqttClose);
    yield takeLatest(mqttError, handleMqttClose);
    yield takeLatest(clientDisconnectedAction, clientDisconnectedWorker);
    yield takeLatest(mqttConnectedAction, mqttConnectedWorker);
    yield takeLatest(checkClientConnectedAction, checkClientConnectionWorker)
    yield takeLatest(mqttFailureAction, mqttFailureWorker);
}

// Define saga actions
export const clientDisconnectedAction = createAction('mobileKeyboard/clientDisconnectedAction')
export const mqttConnectedAction = createAction<any| undefined>('mobileKeyboard/mqttConnectedAction')
export const checkClientConnectedAction = createAction('mobileKeyboard/checkClientConnectionAction')
export const mqttReconnectAction = createAction<string | undefined>('mobileKeyboard/mqttReconnectAction')
export const mqttFailureAction = createAction<string | undefined>('mobileKeyboard/mqttFailureAction')

export default serviceSaga;
