import React from 'react';
import ReactDOM from 'react-dom';
import {KatSpinner} from '@amzn/katal-react';

import AppInitWrapper from './components/AppInitWrapper';
import './i18n';

import '@amzn/katal-components/styles.css';
import './index.scss';
import App from "src/components/App";
import {Provider} from "react-redux";
import {store} from "src/redux/store";

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
ReactDOM.render(
    <React.StrictMode>
        <AppInitWrapper>
            <Provider store={store}>
                <React.Suspense fallback={<KatSpinner size='large'/>}>
                    <App/>
                </React.Suspense>
            </Provider>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);
