import {Dispatch} from "react";
import {AnyAction} from "redux";
import {
    CLIENT_ID_PREFIX,
    DisconnectReason,
    FIRE_TV_APP_DOMAIN,
    FTV_CLIENT_ID_PREFIX,
    IOT_CUSTOM_ENDPOINT_PREFIX,
    MessageType,
    MQTT_AUTH_FAILURE_MSG,
    PUBLISH_TOPIC_PREFIX,
    SUBSCRIBE_TOPIC_PREFIX,
    URL_PARAM_QR_TOKEN,
    URL_PARAM_TOPIC
} from "src/constants/MqttConstants";
import {AWSRegion, ClientStatus, CONNECTION_ID_OFFSET, MobileOS} from "src/constants/ServiceConstants";
import {
    closeMqttService,
    mqttDisconnection,
    mqttMessageReceived,
    mqttPublishMessage,
    StateProps
} from "src/redux/firetvReducer";
import initialMetricsPublisher from "src/metrics";
import {MessageReceivedEvent} from "aws-crt/dist/common/mqtt5";
import {toUtf8} from "@aws-sdk/util-utf8-browser";
import {
    clientDisconnectedAction,
    mqttConnectedAction,
    mqttFailureAction,
    mqttReconnectAction
} from "src/redux/firetvSaga";

export const prepareRequest = (type: MessageType, data: string) => {
    return {
        type,
        data
    }
}

export const prepareAuthorizerUsername = (topicName: string, token: string) => {
    return `${URL_PARAM_QR_TOKEN}=${token}&${URL_PARAM_TOPIC}=${topicName}`;
}

export const prepareMobileClientId = (rawClientId: string, connectionId: string) => {
    return CLIENT_ID_PREFIX + rawClientId + "/" + connectionId;
}

export const prepareFireTvClientId = (rawClientId: string, connectionId: string) => {
    return FTV_CLIENT_ID_PREFIX + rawClientId + "/" + connectionId
}

export const preparePublishTopicName = (topic: string, connectionId: string) => {
    return PUBLISH_TOPIC_PREFIX + topic + "/" + connectionId
}

export const prepareSubscribeTopicName = (topic: string, connectionId: string) => {
    return SUBSCRIBE_TOPIC_PREFIX + topic + "/" + connectionId
}

export const prepareIOTEndpoint = (region: string) => {
    return `${IOT_CUSTOM_ENDPOINT_PREFIX}-${awsRegionToRegion(region)}.${FIRE_TV_APP_DOMAIN}`;
}

export const awsRegionToRegion = (awsRegion: any): string | null => {
    switch (awsRegion) {
        case "us-east-1":
            return "na";
        case "us-west-2":
            return "fe";
        case "eu-west-1":
            return "eu";
    }
    return null;
}

/**
 * Converts string to enum for supported regions from AWSRegion enum
 * @param region
 * @return AWS Region if supported region else null
 */
export const regionToAWSRegion = (region: any): string | null => {
    switch (region) {
        case "NA":
            return AWSRegion.US_EAST_1;
        case "FE":
            return AWSRegion.US_WEST_2;
        case "EU":
            return AWSRegion.EU_WEST_1;
    }
    return null;
}

/**
 * Handles disconnect reason code for MQTT and updates the status accordingly
 * Ref - https://docs.aws.amazon.com/iot/latest/developerguide/mqtt.html#mqtt5-reason-codes
 * @param dispatch
 * @param event
 */
export const handleDisconnectEvent = (dispatch: Dispatch<AnyAction>, event: any) => {
    if (event.disconnect) {
        switch (event.disconnect.reasonCode) {
            case 135:
                console.info("MQTT disconnect: Not Authorized, reconnecting")
                dispatch(mqttDisconnection(DisconnectReason.AUTH_FAILURE))
                break
            case 142:
                console.info("MQTT disconnect: Session taken over, stopping");
                dispatch(mqttDisconnection(DisconnectReason.SESSION_TAKEN_OVER))
                break
            default:
                dispatch(mqttDisconnection(DisconnectReason.OTHERS))
        }
    } else {
        dispatch(mqttReconnectAction())
    }
}

/**
 * Handles failure reason for MQTT and updates the status accordingly
 * @param dispatch
 * @param event
 */
export const handleFailureEvent = (dispatch: Dispatch<AnyAction>, event: any) => {
    if (event.error.error_name === MQTT_AUTH_FAILURE_MSG) {
        console.info("MQTT failure: Not Authorized, stopping")
        dispatch(mqttFailureAction())
    } else {
        dispatch(mqttDisconnection(DisconnectReason.OTHERS))
    }
}

/**
 * Handles connect reason code for MQTT and updates the status accordingly
 * Ref - https://docs.aws.amazon.com/iot/latest/developerguide/mqtt.html#mqtt5-reason-codes
 *
 * @param dispatch
 * @param event
 */
export const handleConnectEvent = (dispatch: Dispatch<AnyAction>, event: any) => {
    switch (event.connack?.reasonCode) {
        case 134:
            dispatch(mqttDisconnection(DisconnectReason.AUTH_FAILURE))
            break;
        case 135:
            console.info("MQTT disconnect: Not Authorized, reconnecting")
            dispatch(mqttDisconnection(DisconnectReason.AUTH_FAILURE))
            break;
        case 144:
            console.info("MQTT disconnect: Invalid token, stopping");
            dispatch(closeMqttService(ClientStatus.DISCONNECTED))
            break;
        case 0:
            dispatch(mqttConnectedAction(event))
    }
}

/**
 * Detects the OS based on userAgent set by browser
 */
export const getMobileOS = (): MobileOS => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return MobileOS.Android
    } else if ((/iPad|iPhone|iPod/.test(ua)) || navigator.maxTouchPoints > 1) {
        return MobileOS.iOS
    }
    return MobileOS.Unknown
}


/**
 * Dispatches message to MQTT topic
 * @param messageType - Type of message defined in {@link MessageType}
 * @param message - Text to be sent to topic
 * @param dispatch - a reference to the dispatch function from the Redux store
 * @param state
 */
export const handleUpdate = (messageType: MessageType, message: string, dispatch: Dispatch<AnyAction>, state: StateProps) => {
    if (state.firstPublish) {
        const metrics = initialMetricsPublisher.newChildActionPublisherForMethod('FireTVPublish');
        if (messageType == MessageType.NAVIGATION) {
            metrics.publishCounterMonitor('FirstTouch.Dpad', 1);
        }
        else if (messageType == MessageType.KEYBOARD) {
            metrics.publishCounterMonitor('FirstTouch.Keyboard', 1);
        }
    }
    dispatch(mqttPublishMessage(JSON.stringify(prepareRequest(messageType, message))))
}

/**
 * Dispatches action based on mqtt topic
 * @param dispatch
 * @param data 
 * @param state 
 */
export const handleMqttMessages = (dispatch: Dispatch<AnyAction>, data: MessageReceivedEvent, state: StateProps) => {
    console.log("Received: ", toUtf8(new Uint8Array(data.message.payload as ArrayBuffer)));

    let topic = data.message.topicName;

    switch(topic) {
        case state.subscribeTopics.connectTopic:
            console.log("Client Connected", data);
            break;
        case state.subscribeTopics.disconnectTopic:
            dispatch(clientDisconnectedAction())
            break;
        case state.subscribeTopics.ftvClient:
            dispatch(mqttMessageReceived(data));
            break;
    } 
}

/**
 * Generates connectionId used to identify mobile connection
 */
export const newConnectionId = (): string | null => {
    return Math.floor(Math.random() * CONNECTION_ID_OFFSET) + "-" + Date.now();
}

/**
 * Returns ClientStatus for received status from FireTV
 * @param status
 */
export const getClientStatus = (status: string) : ClientStatus => {
    if (status == "CONNECTED") {
        return ClientStatus.CONNECTED;
    } else if (status == "RESTRICTED") {
        return ClientStatus.RESTRICTED;
    } else if (status == "PENDING_AUTHORIZATION") {
        return ClientStatus.PENDING_AUTHORIZATION;
    } else if (status == "DISCONNECTED") {
        return ClientStatus.DISCONNECTED;
    } else {
        return ClientStatus.AUTHENTICATION_FAILURE;
    }
}