import {useState} from "react";
import {HideButton} from "src/components/HideButton";
import {ShowButton} from "src/components/ShowButton";

/**
 * Component to handle show and hide button for password field
 * @param onClick - Callback on button click
 */
export const PasswordButton = ({onClick}: { onClick: (e: any) => void }) => {
    const [hidden, setHidden] = useState<boolean>(true);

    const handleClick = (e: any) => {
        setHidden(!hidden);
        onClick(e);
    }

    if (hidden) {
       return (<ShowButton onClick={(e) => handleClick(e)}/>);
    } else {
        return (<HideButton onClick={(e) => handleClick(e)}/>);
    }
}