import {getMobileOS} from "src/utils/ServiceUtils";
import {APP_LINK, MobileOS} from "src/constants/ServiceConstants";
import {IOSBadge} from "src/components/iOSBadge";

import {GooglePlayBadge} from "src/icons/GooglePlayBadge";
import {AppIcon} from "src/icons/AppIcon";

export const Badge = () => {

    const OS = getMobileOS();

    const redirectToStore = () => {
        window.location.href = APP_LINK;
    }

    return (
        <>
            <div>
                <AppIcon onClick={redirectToStore}/>
            </div>
            <div>
            {
                (() => {
                    if (OS === MobileOS.iOS) {
                        return (
                            <>
                                <div className="app-icon-ios">
                                    <IOSBadge onClick={redirectToStore}/>
                                </div>
                            </>
                        )
                    } else {
                        // Keeping android for all others
                        return (
                            <>
                                <div>
                                    <GooglePlayBadge onClick={redirectToStore}/>
                                </div>
                            </>
                        )
                    }
                })()
            }
            </div>
        </>
    )
}