/**
 * SVG reference - https://tiny.amazon.com/l33p363x/figmfile7hO6Squi
 */
export const HideButton = ({onClick}: { onClick: (e: any) => void }) => {
    return (
        <div className="hide-button" onClick={onClick} onMouseDown={e => e.preventDefault()}>
            <svg width="22" height="20" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.70695 19.7068L20.7069 1.70676C21.0979 1.31576 21.0979 0.683762 20.7069 0.292762C20.3159 -0.0982383 19.6839 -0.0982383 19.2929 0.292762L1.29295 18.2928C0.901945 18.6838 0.901945 19.3158 1.29295 19.7068C1.48795 19.9018 1.74395 19.9998 1.99995 19.9998C2.25595 19.9998 2.51195 19.9018 2.70695 19.7068ZM17.8739 5.95476L14.8609 8.96576C14.9509 9.29776 15.0139 9.63976 15.0139 9.99976C15.0139 12.2088 13.2229 13.9998 11.0139 13.9998C10.6539 13.9998 10.3109 13.9368 9.97995 13.8478L8.15195 15.6758C9.06995 15.8778 10.0189 15.9998 10.9999 15.9998C15.4159 15.9998 19.3019 13.8438 21.6219 10.5668C21.8589 10.2308 21.8589 9.76876 21.6219 9.43276C20.6339 8.03776 19.3499 6.86276 17.8739 5.95476ZM0.377945 10.5668C0.140945 10.2308 0.140945 9.76876 0.377945 9.43276C2.69795 6.15576 6.58395 3.99976 10.9999 3.99976C11.9809 3.99976 12.9309 4.12176 13.8479 4.32376L12.0259 6.14476C11.7009 6.05976 11.3659 5.99976 11.0139 5.99976C8.80494 5.99976 7.01395 7.79076 7.01395 9.99976C7.01395 10.3518 7.07395 10.6868 7.15895 11.0128L4.12595 14.0458C2.64995 13.1368 1.36595 11.9618 0.377945 10.5668Z"
                      fill="white"/>
            </svg>
        </div>
    )
}