export const IOT_CUSTOM_ENDPOINT_PREFIX = "connect.keyboard";
export const FIRE_TV_APP_DOMAIN = "amazonfiretvapp.com";
export const IOT_AUTHORIZER_NAME = "default-authorizer";
export const CLIENT_ID_PREFIX = 'mobile-';
export const FTV_CLIENT_ID_PREFIX = 'firetv-';
export const PUBLISH_TOPIC_PREFIX = 'keyboard/mobile/';
export const SUBSCRIBE_TOPIC_PREFIX = 'keyboard/firetv/';
export const CLIENT_CONNECTED_TOPIC_PREFIX = '$aws/events/presence/connected/';
export const CLIENT_DISCONNECTED_TOPIC_PREFIX = '$aws/events/presence/disconnected/';

export const MQTT_AUTH_FAILURE_MSG = "Error: Connection refused: Not authorized";

export const URL_PARAM_QR_TOKEN = "qr_token";
export const URL_PARAM_TOPIC = "topic";

export enum MessageType {
    KEYBOARD = "KEYBOARD",
    NAVIGATION = "DPAD",
    GENERAL = "UPDATE",
    KEYBOARD_STATE = "KEYBOARD_STATE"
}

export enum Navigation {
    UP = "UP",
    DOWN = "DOWN",
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    CENTER = "CENTER",
    SUBMIT = "SUBMIT"
}

export enum MqttStatus {
    NOT_INITIALIZED,
    INITIALIZED,
    ATTEMPTING,
    CONNECTION_SUCCESS,
    STARTED,
    STOPPED,
    CLOSED,
    RECONNECTING
}

export enum DisconnectReason {
    SESSION_TAKEN_OVER,
    AUTH_FAILURE,
    OTHERS
}