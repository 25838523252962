import {
    APPSTORE_LINK_DESC,
    AUTH_FAILURE_MESSAGE,
    ClientStatus,
    CONNECTING_MESSAGE,
    DISCONNECTED_MESSAGE,
    OverlayStatus,
    PENDING_AUTHORIZATION_MESSAGE,
    RESTRICTED_STATUS
} from "src/constants/ServiceConstants";
import '../styles/overlay.scss';
import {Badge} from "src/components/Badge";
import React from "react";
import {useSelector} from "react-redux";
import {StateProps} from "src/redux/firetvReducer";

export const Overlay = () => {
    const stateData: StateProps = useSelector((state: any) => state.mobileKeyboard);
    const connectionStatus: ClientStatus = stateData.connectionStatus;
    const pin: string = stateData.pin;

    let displayStatus = OverlayStatus.CONNECTING;

    switch (connectionStatus) {
        case ClientStatus.INITIALIZING:
        case ClientStatus.CONNECTING:
        case ClientStatus.RECONNECTING:
            displayStatus = OverlayStatus.CONNECTING;
            break;
        case ClientStatus.DISCONNECTED:
            displayStatus = OverlayStatus.DISCONNECTED;
            break;
        case ClientStatus.AUTHENTICATION_FAILURE:
            displayStatus = OverlayStatus.AUTHENTICATION_FAILURE;
            break;
        case ClientStatus.RESTRICTED:
            displayStatus = OverlayStatus.RESTRICTED;
            break;
        case ClientStatus.PENDING_AUTHORIZATION:
            displayStatus = OverlayStatus.PENDING_AUTHORIZATION;
            break;
    }

    return (
        <div className="container">
            <div className="connection-status">
                <div className="status">
                    {displayStatus}
                </div>
            </div>
            {(() => {
                if (displayStatus == OverlayStatus.CONNECTING)
                    return (
                        <>
                            <div className="status-desc">
                                <p>{CONNECTING_MESSAGE}</p>
                            </div>
                        </>
                    );
                else if (displayStatus == OverlayStatus.AUTHENTICATION_FAILURE)
                    return (
                        <>
                            <div className="status-desc">
                                <p>{AUTH_FAILURE_MESSAGE}</p>
                            </div>
                        </>
                    );
                else if (displayStatus == OverlayStatus.RESTRICTED)
                    return (
                        <>
                            <div className="status-desc">
                                <p>{RESTRICTED_STATUS}</p>
                            </div>
                            <div className="banner-container">
                                <p className="appstore-link">
                                    {APPSTORE_LINK_DESC}
                                </p>
                            </div>
                            <div className="badge-container">
                                <Badge/>
                            </div>
                        </>
                    );
                else if (displayStatus == OverlayStatus.PENDING_AUTHORIZATION)
                    return (
                        <>
                            <div className="status-desc">
                                <p>{PENDING_AUTHORIZATION_MESSAGE}</p>
                                <h1>{pin}</h1>
                            </div>
                            <div className="banner-container">
                                <p className="appstore-link">
                                    {APPSTORE_LINK_DESC}
                                </p>
                            </div>
                            <div className="badge-container">
                                <Badge/>
                            </div>
                        </>
                    );
                else
                    return (
                        <>
                            <div className="status-desc">
                                <p>{DISCONNECTED_MESSAGE}</p>
                            </div>
                            <div className="banner-container">
                                <p className="appstore-link">
                                    {APPSTORE_LINK_DESC}
                                </p>
                            </div>
                            <div className="badge-container">
                                <Badge/>
                            </div>
                        </>
                    );
            })()}
        </div>
    )
}
