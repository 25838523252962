/**
 * SVG reference - https://tiny.amazon.com/l33p363x/figmfile7hO6Squi
 */
export const ShowButton = ({onClick}: { onClick: (e: any) => void }) => {
    return (
        <div className="show-button" onClick={onClick} onMouseDown={e => e.preventDefault()}>
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path id="Fill" fillRule="evenodd" clipRule="evenodd" d="M11.0137 10C8.8047 10 7.0137 8.209 7.0137 6C7.0137 3.791 8.8047 2 11.0137 2C13.2227 2 15.0137 3.791 15.0137 6C15.0137 8.209 13.2227 10 11.0137 10ZM10.9997 0C6.5837 0 2.6987 2.156 0.378695 5.433C0.140695 5.769 0.140695 6.231 0.378695 6.567C2.6987 9.844 6.5837 12 10.9997 12C15.4157 12 19.3017 9.844 21.6217 6.567C21.8597 6.231 21.8597 5.769 21.6217 5.433C19.3017 2.156 15.4157 0 10.9997 0Z" fill="white"/>
                </g>
            </svg>
        </div>
    )
}