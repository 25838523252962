export const GET_PARAM_TOKEN = 't';
export const GET_PARAM_TOPIC_NAME = 'tn';
export const GET_PARAM_CLIENT_ID = 'c';
export const GET_PARAM_REGION = 'r';

export const DEFAULT_LABEL = 'Enter text here';
export const CONNECTING_MESSAGE = 'Connecting to your Fire TV...'
export const AUTH_FAILURE_MESSAGE = 'Unable to connect Fire TV with your phone. Try scanning the QR code again.'
export const DISCONNECTED_MESSAGE = 'To use the mobile keyboard, open the keyboard on your Fire TV, then scan the QR code.'
export const RESTRICTED_STATUS = 'This mobile keyboard is available only when the Fire TV keyboard is onscreen. To control Fire TV with your phone anytime, download the Fire TV app.'
export const PENDING_AUTHORIZATION_MESSAGE = 'Follow the directions on your TV.'

export const APPSTORE_LINK_DESC = 'Make text entry and TV control easier by downloading the Fire TV app.'
export const APP_LINK = 'https://getstarted.amazonfiretvapp.com'

export const FIRE_TV_COOKIE_NAME = 'FireTV'
export const CONNECTION_ID_COOKIE_NAME = 'QrKeyboardConnectionId'
export const CONNECTION_ID_OFFSET = 1000000000000
export const COOKIE_EXPIRATION_DATE = new Date('2044-01-01T00:00:00.000Z');

export const MAX_RECONNECT = 5;
export const RECONNECT_RETRY_DELAY_IN_MS = 1000;
export const MAX_WAIT_FOR_CLIENT_IN_MS = 30000;

export enum ClientStatus {
    INITIALIZING,
    CONNECTED,
    DISCONNECTED,
    CONNECTING,
    RECONNECTING,
    AUTHENTICATION_FAILURE,
    PENDING_AUTHORIZATION,
    RESTRICTED
}

export enum OverlayStatus {
    CONNECTING = "Connecting",
    AUTHENTICATION_FAILURE= "Failed to Connect",
    DISCONNECTED= "Not Connected",
    RESTRICTED= "Not Available",
    PENDING_AUTHORIZATION= "CONFIRM CODE"
}

export enum AWSRegion {
    US_EAST_1 = "us-east-1",
    US_WEST_2 = "us-west-2",
    EU_WEST_1 = "eu-west-1"
}

export enum InputType {
    TEXT = "text",
    PASSWORD = "password"
}

export enum FireTVLayoutType {
    STANDARD_KEYBOARD = "STANDARD_KEYBOARD",
    PASSWORD_KEYBOARD = "PASSWORD_KEYBOARD",
    NO_INPUT_KEYBOARD = "NO_INPUT_KEYBOARD"
}

// Key codes (Ref - https://lists.w3.org/Archives/Public/www-dom/2010JulSep/att-0182/keyCode-spec.html#fixed-virtual-key-codes)
export enum KeyCode {
    Enter = 13
}

export enum MobileOS {
    iOS,
    Android,
    Unknown
}