export const IOSBadge = ({onClick}: { onClick: (e: any) => void }) => {
    return (
        <div className="ios-store-badge" onClick={onClick}>
            <svg width="121" height="40" viewBox="0 0 121 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917 1" clipPath="url(#clip0_431_1507)">
                    <g id="Group">
                        <g id="Group_2">
                            <g id="Group_3">
                                <path id="Vector" d="M110.803 0.00013H10.2026C9.83595 0.00013 9.47365 0.00013 9.10792 0.00213C8.80177 0.00413 8.49806 0.00994 8.18897 0.01483C7.51748 0.0227318 6.84758 0.0818063 6.18507 0.19154C5.52349 0.303663 4.88264 0.515046 4.28419 0.81854C3.68647 1.1246 3.14031 1.5223 2.66554 1.9972C2.18827 2.47077 1.79043 3.01815 1.48732 3.61829C1.18337 4.21724 0.972609 4.85907 0.862319 5.52161C0.75098 6.18332 0.691067 6.85265 0.683119 7.52361C0.673839 7.83021 0.672859 8.13783 0.667969 8.44447V31.5587C0.672859 31.8692 0.673839 32.17 0.683119 32.4806C0.69107 33.1516 0.750982 33.8209 0.862319 34.4825C0.972305 35.1455 1.18308 35.7877 1.48732 36.3868C1.79029 36.985 2.18819 37.5302 2.66554 38.0011C3.13851 38.4781 3.68502 38.8761 4.28419 39.1798C4.88264 39.4841 5.52342 39.6968 6.18507 39.8106C6.84769 39.9195 7.51753 39.9786 8.18897 39.9874C8.49806 39.9942 8.80177 39.9981 9.10792 39.9981C9.47364 40.0001 9.83597 40.0001 10.2026 40.0001H110.803C111.162 40.0001 111.527 40.0001 111.887 39.9981C112.191 39.9981 112.504 39.9942 112.809 39.9874C113.479 39.9791 114.147 39.92 114.809 39.8106C115.472 39.696 116.116 39.4834 116.717 39.1798C117.315 38.8759 117.861 38.478 118.334 38.0011C118.81 37.5284 119.209 36.9836 119.516 36.3868C119.818 35.7872 120.026 35.1451 120.135 34.4825C120.246 33.8208 120.308 33.1516 120.32 32.4806C120.324 32.17 120.324 31.8692 120.324 31.5587C120.332 31.1954 120.332 30.8341 120.332 30.4649V9.53626C120.332 9.17005 120.332 8.80677 120.324 8.44447C120.324 8.13783 120.324 7.83021 120.32 7.52357C120.308 6.85255 120.246 6.18337 120.135 5.52157C120.026 4.85941 119.817 4.21763 119.516 3.61825C118.898 2.41533 117.92 1.43616 116.717 0.81845C116.115 0.515697 115.472 0.30437 114.809 0.19145C114.148 0.0812328 113.479 0.0221378 112.809 0.01469C112.504 0.00981 112.191 0.00395 111.887 0.002C111.527 0 111.162 0.00013 110.803 0.00013Z" fill="#A6A6A6"/>
                                <path id="Vector_2" d="M9.11284 39.125C8.80816 39.125 8.51084 39.1211 8.20855 39.1143C7.58233 39.1061 6.95757 39.0516 6.33941 38.9512C5.763 38.8519 5.20461 38.6673 4.68267 38.4033C4.16551 38.1415 3.69382 37.7983 3.28567 37.3867C2.87161 36.98 2.52688 36.5082 2.26516 35.9902C2.00054 35.4688 1.81742 34.9099 1.72216 34.333C1.61928 33.7131 1.56362 33.0863 1.55566 32.458C1.54932 32.2471 1.54102 31.5449 1.54102 31.5449V8.44434C1.54102 8.44434 1.54986 7.75293 1.55571 7.5498C1.56333 6.92248 1.61867 6.29665 1.72124 5.67773C1.81667 5.09925 1.99994 4.53875 2.2647 4.01563C2.52546 3.49794 2.86827 3.02586 3.27984 2.61768C3.69094 2.20562 4.16414 1.8606 4.68218 1.59521C5.20292 1.33209 5.76025 1.14873 6.3355 1.05127C6.95569 0.949836 7.58262 0.894996 8.21101 0.88721L9.11333 0.875H111.882L112.795 0.8877C113.418 0.895099 114.039 0.94945 114.653 1.05029C115.234 1.14898 115.798 1.33362 116.324 1.59814C117.362 2.13299 118.207 2.97916 118.739 4.01807C119 4.53758 119.18 5.09351 119.274 5.66699C119.378 6.29099 119.436 6.92174 119.448 7.5542C119.451 7.8374 119.451 8.1416 119.451 8.44434C119.459 8.81934 119.459 9.17627 119.459 9.53613V30.4648C119.459 30.8281 119.459 31.1826 119.451 31.54C119.451 31.8652 119.451 32.1631 119.447 32.4697C119.436 33.0909 119.378 33.7104 119.276 34.3232C119.183 34.9043 119.001 35.4675 118.736 35.9932C118.473 36.5056 118.13 36.9733 117.721 37.3789C117.312 37.7927 116.84 38.1379 116.321 38.4014C115.796 38.6674 115.234 38.8527 114.653 38.9512C114.035 39.0522 113.41 39.1067 112.784 39.1143C112.491 39.1211 112.185 39.125 111.887 39.125L110.803 39.127L9.11284 39.125Z" fill="black"/>
                            </g>
                            <g id="Group62">
                                <g id="Group62">
                                    <g id="Group62">
                                        <path id="&#60;Path&#62;" d="M25.437 20.3008C25.4477 19.4662 25.6694 18.6478 26.0814 17.9218C26.4935 17.1959 27.0824 16.5859 27.7935 16.1488C27.3418 15.5036 26.7458 14.9727 26.053 14.5981C25.3602 14.2236 24.5896 14.0157 23.8024 13.9911C22.1231 13.8148 20.4952 14.9959 19.6395 14.9959C18.7672 14.9959 17.4497 14.0086 16.031 14.0378C15.1133 14.0674 14.2189 14.3343 13.4351 14.8123C12.6512 15.2904 12.0045 15.9633 11.558 16.7656C9.62404 20.1141 11.0666 25.0351 12.9192 27.7417C13.8461 29.0671 14.9294 30.5475 16.3469 30.495C17.7339 30.4375 18.252 29.6105 19.9263 29.6105C21.585 29.6105 22.0711 30.495 23.5173 30.4616C25.0057 30.4375 25.9434 29.1304 26.8378 27.7925C27.5038 26.8481 28.0162 25.8044 28.3562 24.7C27.4915 24.3343 26.7536 23.7221 26.2345 22.9398C25.7153 22.1575 25.438 21.2397 25.437 20.3008Z" fill="white"/>
                                        <path id="&#60;Path&#62;_2" d="M22.7055 12.2108C23.5171 11.2366 23.9169 9.98445 23.8201 8.72021C22.5802 8.85044 21.435 9.44299 20.6125 10.3798C20.2103 10.8375 19.9023 11.3699 19.7061 11.9467C19.5099 12.5235 19.4292 13.1333 19.4688 13.7412C20.0889 13.7476 20.7024 13.6132 21.2631 13.3481C21.8237 13.083 22.3169 12.6942 22.7055 12.2108Z" fill="white"/>
                                    </g>
                                </g>
                                <g id="Group_4">
                                    <path id="Vector_3" d="M42.9702 27.1397H38.2368L37.1001 30.4961H35.0952L39.5786 18.0781H41.6616L46.145 30.4961H44.1059L42.9702 27.1397ZM38.727 25.5908H42.479L40.6294 20.1436H40.5777L38.727 25.5908Z" fill="white"/>
                                    <path id="Vector_4" d="M55.8277 25.9699C55.8277 28.7834 54.3218 30.591 52.0493 30.591C51.4737 30.6211 50.9012 30.4885 50.3974 30.2084C49.8936 29.9283 49.4789 29.5119 49.2007 29.007H49.1577V33.4914H47.2993V21.4426H49.0982V22.9485H49.1324C49.4233 22.446 49.845 22.0318 50.3527 21.7499C50.8603 21.4681 51.4348 21.3291 52.0152 21.3479C54.313 21.3479 55.8277 23.1643 55.8277 25.9699ZM53.9175 25.9699C53.9175 24.1369 52.9702 22.9319 51.5249 22.9319C50.105 22.9319 49.1499 24.1623 49.1499 25.9699C49.1499 27.7942 50.105 29.0158 51.5249 29.0158C52.9702 29.0158 53.9175 27.8196 53.9175 25.9699Z" fill="white"/>
                                    <path id="Vector_5" d="M65.7925 25.9699C65.7925 28.7834 64.2867 30.591 62.0142 30.591C61.4385 30.6211 60.866 30.4885 60.3622 30.2084C59.8584 29.9283 59.4437 29.5119 59.1656 29.007H59.1226V33.4914H57.2642V21.4426H59.063V22.9484H59.0972C59.3881 22.446 59.8098 22.0318 60.3175 21.7499C60.8251 21.4681 61.3997 21.3291 61.98 21.3479C64.2779 21.3479 65.7925 23.1643 65.7925 25.9699ZM63.8824 25.9699C63.8824 24.1369 62.9351 22.9318 61.4898 22.9318C60.0698 22.9318 59.1148 24.1623 59.1148 25.9699C59.1148 27.7942 60.0698 29.0158 61.4898 29.0158C62.9351 29.0158 63.8824 27.8195 63.8824 25.9699Z" fill="white"/>
                                    <path id="Vector_6" d="M72.3784 27.0362C72.5161 28.2676 73.7124 29.0762 75.3472 29.0762C76.9136 29.0762 78.0406 28.2676 78.0406 27.1572C78.0406 26.1934 77.3609 25.6162 75.7515 25.2207L74.1421 24.833C71.8619 24.2822 70.8033 23.2158 70.8033 21.4854C70.8033 19.3428 72.6704 17.8711 75.3218 17.8711C77.9458 17.8711 79.7447 19.3428 79.8052 21.4854H77.9292C77.8169 20.2461 76.7925 19.4981 75.2954 19.4981C73.7983 19.4981 72.7739 20.2549 72.7739 21.3565C72.7739 22.2344 73.4282 22.751 75.0288 23.1465L76.397 23.4824C78.9448 24.0849 80.0034 25.1084 80.0034 26.9248C80.0034 29.248 78.1528 30.7031 75.2095 30.7031C72.4556 30.7031 70.5962 29.2822 70.4761 27.0361L72.3784 27.0362Z" fill="white"/>
                                    <path id="Vector_7" d="M84.0142 19.2998V21.4424H85.7358V22.9141H84.0142V27.9053C84.0142 28.6807 84.3589 29.042 85.1157 29.042C85.3201 29.0384 85.5242 29.0241 85.727 28.999V30.4619C85.3868 30.5255 85.0409 30.5543 84.6948 30.5478C82.8618 30.5478 82.147 29.8593 82.147 28.1035V22.9141H80.8306V21.4424H82.1469V19.2998H84.0142Z" fill="white"/>
                                    <path id="Vector_8" d="M86.7329 25.9697C86.7329 23.1211 88.4106 21.3311 91.0269 21.3311C93.6519 21.3311 95.3218 23.1211 95.3218 25.9697C95.3218 28.8262 93.6606 30.6084 91.0269 30.6084C88.394 30.6084 86.7329 28.8262 86.7329 25.9697ZM93.4282 25.9697C93.4282 24.0156 92.5327 22.8623 91.0268 22.8623C89.521 22.8623 88.6265 24.0244 88.6265 25.9697C88.6265 27.9316 89.521 29.0762 91.0268 29.0762C92.5327 29.0762 93.4282 27.9316 93.4282 25.9697Z" fill="white"/>
                                    <path id="Vector_9" d="M96.854 21.4425H98.6265V22.9835H98.6695C98.7894 22.5022 99.0713 22.0769 99.4679 21.779C99.8645 21.4811 100.352 21.3288 100.847 21.3478C101.061 21.347 101.275 21.3703 101.484 21.4171V23.1554C101.214 23.0728 100.932 23.0348 100.649 23.0431C100.379 23.0321 100.11 23.0797 99.86 23.1826C99.6101 23.2855 99.3855 23.4412 99.2015 23.6391C99.0175 23.837 98.8786 24.0724 98.7941 24.3291C98.7097 24.5857 98.6818 24.8576 98.7124 25.1261V30.4962H96.854L96.854 21.4425Z" fill="white"/>
                                    <path id="Vector_10" d="M110.052 27.8369C109.802 29.4805 108.202 30.6084 106.154 30.6084C103.52 30.6084 101.885 28.8437 101.885 26.0127C101.885 23.1729 103.529 21.3311 106.076 21.3311C108.581 21.3311 110.156 23.0518 110.156 25.7969V26.4336H103.761V26.5459C103.732 26.8791 103.773 27.2148 103.884 27.5306C103.994 27.8464 104.17 28.1352 104.4 28.3778C104.631 28.6203 104.91 28.8111 105.22 28.9374C105.529 29.0637 105.863 29.1226 106.197 29.1103C106.636 29.1515 107.077 29.0498 107.453 28.8203C107.83 28.5909 108.123 28.246 108.288 27.8369L110.052 27.8369ZM103.77 25.1348H108.296C108.313 24.8352 108.268 24.5354 108.163 24.2541C108.058 23.9729 107.897 23.7164 107.688 23.5006C107.48 23.2849 107.229 23.1145 106.951 23.0003C106.674 22.8861 106.376 22.8305 106.076 22.8369C105.773 22.8351 105.473 22.8933 105.193 23.008C104.913 23.1227 104.658 23.2918 104.444 23.5054C104.23 23.7191 104.06 23.973 103.944 24.2527C103.828 24.5323 103.769 24.8321 103.77 25.1348Z" fill="white"/>
                                </g>
                            </g>
                        </g>
                        <g id="Group62">
                            <g id="Group_5">
                                <path id="Vector_11" d="M38.4941 8.73089C38.8838 8.70293 39.2747 8.76179 39.6388 8.90323C40.0029 9.04466 40.3311 9.26514 40.5997 9.54876C40.8683 9.83239 41.0706 10.1721 41.192 10.5434C41.3134 10.9146 41.3509 11.3082 41.3018 11.6957C41.3018 13.602 40.2715 14.6977 38.4941 14.6977H36.3389V8.73089H38.4941ZM37.2656 13.8539H38.3906C38.669 13.8705 38.9476 13.8249 39.2062 13.7203C39.4647 13.6157 39.6967 13.4547 39.8852 13.2491C40.0737 13.0436 40.2139 12.7986 40.2958 12.532C40.3777 12.2653 40.399 11.9838 40.3584 11.7079C40.3961 11.4331 40.3725 11.1533 40.2894 10.8886C40.2063 10.624 40.0657 10.381 39.8776 10.177C39.6896 9.9731 39.4587 9.81329 39.2016 9.70905C38.9445 9.60481 38.6676 9.55873 38.3906 9.5741H37.2656V13.8539Z" fill="white"/>
                                <path id="Vector_12" d="M42.3484 12.4443C42.3201 12.1484 42.354 11.8498 42.4478 11.5677C42.5416 11.2857 42.6934 11.0263 42.8933 10.8063C43.0933 10.5864 43.337 10.4106 43.6089 10.2903C43.8807 10.17 44.1747 10.1079 44.472 10.1079C44.7692 10.1079 45.0632 10.17 45.3351 10.2903C45.6069 10.4106 45.8506 10.5864 46.0506 10.8063C46.2505 11.0263 46.4023 11.2857 46.4961 11.5677C46.59 11.8498 46.6238 12.1484 46.5955 12.4443C46.6243 12.7405 46.5909 13.0395 46.4973 13.322C46.4037 13.6045 46.252 13.8644 46.052 14.0848C45.8521 14.3052 45.6082 14.4813 45.336 14.6019C45.0639 14.7224 44.7696 14.7847 44.472 14.7847C44.1743 14.7847 43.88 14.7224 43.6079 14.6019C43.3358 14.4813 43.0919 14.3052 42.8919 14.0848C42.6919 13.8644 42.5402 13.6045 42.4466 13.322C42.353 13.0395 42.3196 12.7405 42.3484 12.4443ZM45.6814 12.4443C45.6814 11.4682 45.2429 10.8974 44.4734 10.8974C43.701 10.8974 43.2664 11.4682 43.2664 12.4443C43.2664 13.4282 43.701 13.9946 44.4734 13.9946C45.243 13.9946 45.6814 13.4243 45.6814 12.4443Z" fill="white"/>
                                <path id="Vector_13" d="M52.2412 14.6978H51.3193L50.3887 11.3813H50.3184L49.3916 14.6978H48.4785L47.2373 10.1948H48.1387L48.9453 13.6308H49.0117L49.9375 10.1948H50.79L51.7158 13.6308H51.7861L52.5889 10.1948H53.4775L52.2412 14.6978Z" fill="white"/>
                                <path id="Vector_14" d="M54.5215 10.1949H55.377V10.9102H55.4434C55.556 10.6533 55.746 10.4379 55.9869 10.2941C56.2278 10.1503 56.5075 10.0852 56.7871 10.1079C57.0062 10.0915 57.2262 10.1245 57.4308 10.2046C57.6354 10.2847 57.8194 10.4098 57.969 10.5707C58.1187 10.7315 58.2303 10.924 58.2955 11.1338C58.3607 11.3436 58.3779 11.5654 58.3457 11.7827V14.6977H57.457V12.0059C57.457 11.2823 57.1426 10.9224 56.4854 10.9224C56.3366 10.9155 56.1881 10.9408 56.05 10.9966C55.912 11.0525 55.7876 11.1375 55.6855 11.2459C55.5834 11.3543 55.5059 11.4834 55.4584 11.6246C55.4108 11.7657 55.3944 11.9154 55.4102 12.0635V14.6978H54.5215L54.5215 10.1949Z" fill="white"/>
                                <path id="Vector_15" d="M59.7617 8.43701H60.6504V14.6978H59.7617V8.43701Z" fill="white"/>
                                <path id="Vector_16" d="M61.8855 12.4444C61.8572 12.1484 61.8911 11.8499 61.985 11.5678C62.0788 11.2857 62.2306 11.0263 62.4306 10.8064C62.6305 10.5864 62.8743 10.4106 63.1461 10.2903C63.418 10.17 63.712 10.1079 64.0093 10.1079C64.3066 10.1079 64.6006 10.17 64.8725 10.2903C65.1443 10.4106 65.3881 10.5864 65.588 10.8064C65.788 11.0263 65.9398 11.2857 66.0336 11.5678C66.1275 11.8499 66.1614 12.1484 66.1331 12.4444C66.1619 12.7406 66.1284 13.0396 66.0348 13.3221C65.9412 13.6046 65.7895 13.8645 65.5894 14.0849C65.3894 14.3053 65.1455 14.4814 64.8734 14.6019C64.6013 14.7225 64.3069 14.7847 64.0093 14.7847C63.7117 14.7847 63.4173 14.7225 63.1452 14.6019C62.873 14.4814 62.6291 14.3053 62.4291 14.0849C62.2291 13.8645 62.0774 13.6046 61.9838 13.3221C61.8902 13.0396 61.8567 12.7406 61.8855 12.4444ZM65.2185 12.4444C65.2185 11.4683 64.78 10.8975 64.0105 10.8975C63.238 10.8975 62.8035 11.4683 62.8035 12.4444C62.8035 13.4283 63.2381 13.9947 64.0105 13.9947C64.78 13.9947 65.2185 13.4244 65.2185 12.4444Z" fill="white"/>
                                <path id="Vector_17" d="M67.0688 13.4243C67.0688 12.6138 67.6724 12.1465 68.7436 12.0801L69.9634 12.0098V11.6211C69.9634 11.1455 69.6489 10.877 69.0415 10.877C68.5454 10.877 68.2017 11.0591 68.103 11.3774H67.2427C67.3335 10.604 68.061 10.1079 69.0825 10.1079C70.2114 10.1079 70.8481 10.6699 70.8481 11.6211V14.6978H69.9927V14.0649H69.9224C69.7796 14.2919 69.5792 14.477 69.3416 14.6012C69.104 14.7254 68.8377 14.7843 68.5698 14.7719C68.3808 14.7916 68.1897 14.7714 68.009 14.7127C67.8282 14.654 67.6618 14.5581 67.5203 14.4311C67.3789 14.3042 67.2657 14.149 67.1879 13.9756C67.1102 13.8021 67.0696 13.6144 67.0688 13.4243ZM69.9634 13.0395V12.6631L68.8638 12.7334C68.2436 12.7749 67.9624 12.9858 67.9624 13.3828C67.9624 13.7881 68.314 14.0239 68.7974 14.0239C68.939 14.0382 69.0821 14.0239 69.2181 13.9819C69.3541 13.9398 69.4803 13.8707 69.5891 13.7789C69.6979 13.6871 69.7871 13.5743 69.8514 13.4473C69.9157 13.3202 69.9538 13.1816 69.9634 13.0395Z" fill="white"/>
                                <path id="Vector_18" d="M72.0161 12.4444C72.0161 11.0215 72.7476 10.1201 73.8853 10.1201C74.1667 10.1072 74.446 10.1746 74.6905 10.3145C74.935 10.4544 75.1347 10.661 75.2661 10.9101H75.3325V8.43701H76.2212V14.6978H75.3696V13.9863H75.2993C75.1577 14.2338 74.9511 14.4378 74.7019 14.5763C74.4526 14.7148 74.1702 14.7825 73.8853 14.772C72.7398 14.772 72.0161 13.8706 72.0161 12.4444ZM72.9341 12.4444C72.9341 13.3994 73.3843 13.9741 74.1372 13.9741C74.8862 13.9741 75.3492 13.3911 75.3492 12.4483C75.3492 11.5098 74.8814 10.9185 74.1372 10.9185C73.3892 10.9185 72.9341 11.4971 72.9341 12.4444Z" fill="white"/>
                                <path id="Vector_19" d="M79.8977 12.4443C79.8694 12.1484 79.9033 11.8498 79.9971 11.5677C80.091 11.2857 80.2427 11.0263 80.4427 10.8063C80.6426 10.5864 80.8863 10.4106 81.1582 10.2903C81.43 10.17 81.724 10.1079 82.0213 10.1079C82.3185 10.1079 82.6125 10.17 82.8844 10.2903C83.1562 10.4106 83.3999 10.5864 83.5999 10.8063C83.7998 11.0263 83.9516 11.2857 84.0454 11.5677C84.1393 11.8498 84.1731 12.1484 84.1448 12.4443C84.1737 12.7405 84.1402 13.0395 84.0466 13.322C83.953 13.6045 83.8014 13.8644 83.6014 14.0848C83.4014 14.3052 83.1575 14.4813 82.8854 14.6019C82.6132 14.7224 82.3189 14.7847 82.0213 14.7847C81.7237 14.7847 81.4293 14.7224 81.1572 14.6019C80.8851 14.4813 80.6412 14.3052 80.4412 14.0848C80.2412 13.8644 80.0895 13.6045 79.9959 13.322C79.9023 13.0395 79.8689 12.7405 79.8977 12.4443ZM83.2307 12.4443C83.2307 11.4682 82.7923 10.8974 82.0227 10.8974C81.2503 10.8974 80.8157 11.4682 80.8157 12.4443C80.8157 13.4282 81.2503 13.9946 82.0227 13.9946C82.7923 13.9946 83.2307 13.4243 83.2307 12.4443Z" fill="white"/>
                                <path id="Vector_20" d="M85.3374 10.1949H86.1929V10.9102H86.2593C86.3719 10.6533 86.5619 10.4379 86.8028 10.2941C87.0437 10.1503 87.3234 10.0852 87.603 10.1079C87.8221 10.0915 88.0421 10.1245 88.2467 10.2046C88.4513 10.2847 88.6353 10.4098 88.785 10.5707C88.9346 10.7315 89.0462 10.924 89.1114 11.1338C89.1766 11.3436 89.1938 11.5654 89.1616 11.7827V14.6977H88.273V12.0059C88.273 11.2823 87.9585 10.9224 87.3013 10.9224C87.1525 10.9155 87.004 10.9408 86.866 10.9966C86.7279 11.0525 86.6036 11.1375 86.5014 11.2459C86.3993 11.3543 86.3218 11.4834 86.2743 11.6246C86.2268 11.7657 86.2103 11.9154 86.2261 12.0635V14.6978H85.3374V10.1949Z" fill="white"/>
                                <path id="Vector_21" d="M94.1831 9.07373V10.2153H95.1587V10.9639H94.1831V13.2793C94.1831 13.751 94.3774 13.9575 94.8198 13.9575C94.9331 13.9572 95.0462 13.9503 95.1587 13.937V14.6772C94.9991 14.7058 94.8374 14.721 94.6753 14.7226C93.687 14.7226 93.2935 14.375 93.2935 13.5068V10.9638H92.5786V10.2153H93.2935V9.07373H94.1831Z" fill="white"/>
                                <path id="Vector_22" d="M96.3726 8.43701H97.2534V10.9185H97.3237C97.4419 10.6591 97.6371 10.4425 97.8828 10.2982C98.1285 10.1539 98.4127 10.0888 98.6967 10.1118C98.9147 10.1 99.1326 10.1364 99.3349 10.2184C99.5371 10.3004 99.7188 10.4261 99.8669 10.5864C100.015 10.7468 100.126 10.9378 100.192 11.146C100.258 11.3541 100.277 11.5742 100.248 11.7905V14.6978H99.3579V12.0098C99.3579 11.2905 99.0229 10.9263 98.3951 10.9263C98.2423 10.9137 98.0887 10.9347 97.9449 10.9878C97.8011 11.0408 97.6707 11.1247 97.5627 11.2334C97.4547 11.3421 97.3718 11.4732 97.3197 11.6173C97.2676 11.7614 97.2477 11.9152 97.2613 12.0679V14.6977H96.3726L96.3726 8.43701Z" fill="white"/>
                                <path id="Vector_23" d="M105.429 13.482C105.309 13.8935 105.047 14.2495 104.69 14.4877C104.334 14.7258 103.905 14.831 103.478 14.7847C103.182 14.7926 102.887 14.7357 102.614 14.6182C102.342 14.5007 102.098 14.3253 101.9 14.1041C101.702 13.883 101.555 13.6214 101.468 13.3376C101.381 13.0538 101.358 12.7545 101.398 12.4605C101.359 12.1656 101.383 11.8657 101.469 11.581C101.556 11.2964 101.703 11.0336 101.899 10.8105C102.096 10.5874 102.339 10.4093 102.61 10.2881C102.882 10.1669 103.177 10.1054 103.474 10.108C104.727 10.108 105.483 10.964 105.483 12.378V12.6881H102.303V12.7379C102.289 12.9031 102.31 13.0695 102.364 13.2262C102.418 13.383 102.505 13.5267 102.618 13.6481C102.731 13.7696 102.868 13.8661 103.02 13.9315C103.172 13.9969 103.337 14.0297 103.503 14.0279C103.715 14.0534 103.931 14.0151 104.121 13.9179C104.312 13.8207 104.47 13.669 104.574 13.482L105.429 13.482ZM102.303 12.0308H104.578C104.589 11.8797 104.568 11.7279 104.517 11.5852C104.467 11.4425 104.386 11.312 104.282 11.2021C104.177 11.0923 104.051 11.0055 103.911 10.9473C103.771 10.8891 103.621 10.8609 103.469 10.8643C103.316 10.8624 103.163 10.8913 103.021 10.9492C102.878 11.0072 102.749 11.093 102.64 11.2017C102.532 11.3104 102.446 11.4398 102.388 11.5822C102.33 11.7246 102.301 11.8771 102.303 12.0308Z" fill="white"/>
                            </g>
                        </g>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_431_1507">
                        <rect width="119.664" height="40" fill="white" transform="translate(0.667969)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}