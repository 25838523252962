import {FireTVLayoutType, InputType, KeyCode} from "src/constants/ServiceConstants";
import {DismissButton} from "src/components/DismissButton";
import {useEffect, useRef} from "react";
import {PasswordButton} from "src/components/PasswordButton";
import {MessageType, Navigation} from "src/constants/MqttConstants";
import KatalMetricsPublisher from "@amzn/katal-metrics/lib/KatalMetricsPublisher";
import {setInputActive, StateProps} from "src/redux/firetvReducer";
import {useDispatch, useSelector} from "react-redux";
import {handleUpdate} from "src/utils/ServiceUtils";

interface InputGroupProps {
    metricsPublisher: KatalMetricsPublisher
}

export const InputGroup = (props: InputGroupProps) => {

    const stateData: StateProps = useSelector((state: any) => state.mobileKeyboard);
    const dispatch = useDispatch();

    const inputRef = useRef<any>(stateData.inputText);

    const dismissAction = (e: any) => {
        e.preventDefault();
        if (inputRef.current != null && "value" in inputRef.current) {
            inputRef.current.value = '';
            handleUpdate(MessageType.KEYBOARD, inputRef.current.value, dispatch, stateData);
        }
    }

    const handleChange = (event: any) => {
        if (event.keyCode == KeyCode.Enter) {
            handleUpdate(MessageType.NAVIGATION, Navigation.SUBMIT, dispatch, stateData);
            props.metricsPublisher.publishCounterMonitor('Button.Done', 1);
        } else {
            inputRef.current.value = event.target.value;
            handleUpdate(MessageType.KEYBOARD, inputRef.current.value, dispatch, stateData);
        }
    }

    /**
     * When input type is changed in fire tv, change the input type for text box in webapp
     * Note: The input type for input box can change independently on password show/hide button from "passwordToggle" hook
     */
    useEffect(() => {
        switch (stateData.ftvInputType) {
            case FireTVLayoutType.STANDARD_KEYBOARD:
                inputRef.current.type = InputType.TEXT;
                break;
            case FireTVLayoutType.PASSWORD_KEYBOARD:
                inputRef.current.type = InputType.PASSWORD;
                break;
            default:
                inputRef.current.type = InputType.TEXT
        }
    }, [stateData.ftvInputType]);

    const passwordToggle = (e: any) => {
        e.preventDefault();
        if (inputRef.current.type == InputType.PASSWORD) {
            inputRef.current.type = InputType.TEXT
        } else {
            inputRef.current.type = InputType.PASSWORD
        }
    }

    const isActive = (flag: boolean) => {
        dispatch(setInputActive(flag));
    }

    return (
        <div className="input-group">
            <input className="keyboard-input"
                   placeholder='Input'
                   id="data"
                   ref={inputRef}
                   autoComplete="off"
                   onFocus={() => isActive(true)}
                   onBlur={() => isActive(false)}
                   onKeyUp={handleChange}
            />
            <label className="input-label" htmlFor="data">{stateData.labelText}</label>
            <div className="icon">
                {(() => {
                    switch (stateData.ftvInputType) {
                        case FireTVLayoutType.STANDARD_KEYBOARD:
                            return <DismissButton onClick={dismissAction}/>
                        case FireTVLayoutType.PASSWORD_KEYBOARD:
                            return <PasswordButton onClick={passwordToggle}/>
                        default:
                            return <DismissButton onClick={dismissAction}/>
                    }
                })()}
            </div>
        </div>
    )
}