import {BrowserRouter, Route, Routes} from "react-router-dom";
import FireTVKeyboard from "src/pages/FireTVKeyboard";
import {CookiesProvider, useCookies} from "react-cookie";
import {useEffect} from "react";
import initialMetricsPublisher from "src/metrics";
import {COOKIE_EXPIRATION_DATE, FIRE_TV_COOKIE_NAME} from "src/constants/ServiceConstants";

export default function App() {
    const metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('FireTV');

    const [cookies, setCookie] = useCookies([FIRE_TV_COOKIE_NAME]);

    useEffect(() => {
        if (cookies.FireTV && cookies.FireTV.landingCount) {
            let recurringCount = cookies.FireTV.landingCount + 1;
            metricsPublisher.publishCounterMonitor('RecurringUser', recurringCount);
            setCookie(FIRE_TV_COOKIE_NAME, {"landingCount": recurringCount}, {expires : COOKIE_EXPIRATION_DATE})
        } else {
            metricsPublisher.publishCounterMonitor('FirstTimeUser', 1);
            setCookie(FIRE_TV_COOKIE_NAME, {"landingCount": 1}, {expires : COOKIE_EXPIRATION_DATE})
        }
    }, []);

    // This is required to allow active pseudo state in iOS
    document.addEventListener("touchstart", function() {},false);

    return (
        <CookiesProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<FireTVKeyboard/>}/>
                </Routes>
            </BrowserRouter>
        </CookiesProvider>
    );
}