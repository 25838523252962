/**
 * SVG reference - https://tiny.amazon.com/l33p363x/figmfile7hO6Squi
 */
export const DismissButton = ({onClick}: { onClick: (e: any) => void }) => {
    return (
        <div className="dismiss-icon" onClick={onClick}  onMouseDown={e => e.preventDefault()}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path id="icon tint" fillRule="evenodd" clipRule="evenodd"
                          d="M16.2431 14.8281C16.6341 15.2191 16.6341 15.8511 16.2431 16.2421C16.0481 16.4371 15.7921 16.5351 15.5361 16.5351C15.2801 16.5351 15.0241 16.4371 14.8291 16.2421L12.0001 13.4141L9.17112 16.2421C8.97612 16.4371 8.72012 16.5351 8.46412 16.5351C8.20812 16.5351 7.95212 16.4371 7.75712 16.2421C7.36612 15.8511 7.36612 15.2191 7.75712 14.8281L10.5861 12.0001L7.75712 9.17112C7.36612 8.78012 7.36612 8.14812 7.75712 7.75712C8.14812 7.36612 8.78012 7.36612 9.17112 7.75712L12.0001 10.5861L14.8291 7.75812C15.2201 7.36712 15.8521 7.36712 16.2431 7.75812C16.6341 8.14912 16.6341 8.78112 16.2431 9.17212L13.4141 12.0001L16.2431 14.8281Z"
                          fill="#F0F0F0"/>
                </g>
            </svg>
        </div>
    );
}